import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.css']
})
export class LoginComponent implements OnInit {

    user = {
        email: '',
        password: ''
    }
    isLoading = false;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router
    ) {
        console.log('este llego al component')
    }

    ngOnInit() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.add('login-page');

        /* var navbar = document.getElementsByTagName('nav')[0];
        navbar.classList.add('navbar-transparent'); */
    }
    ngOnDestroy() {
        var body = document.getElementsByTagName('body')[0];
        body.classList.remove('login-page');

        /*  var navbar = document.getElementsByTagName('nav')[0];
         navbar.classList.remove('navbar-transparent'); */
    }

    getToken() {
        this.isLoading = true;
        const headers = new HttpHeaders().set("Content-Type", "application/json");
        this.http.post('https://sisapi.aden.org/api/login',
            {
                'user': this.user.email,
                'password': this.user.password
            }).subscribe(
                user => {
                    this.getUser(user);
                },
                error => {
                    console.log('error   ');
                    this.isLoading = false;
                }
            );
    }

    getUser(userRequest) {
        console.log(userRequest);

        const headers = new HttpHeaders().set('Authorization', `Bearer ${userRequest.token}`);
        this.http.get('https://sisapi.aden.org/api/alumno', { headers }).subscribe(
            (user: any) => {
                console.log(user);
                this.toastr.success('Ingresando al simulador!');
                //
                let object = {
                    email: user.email_laboral,
                    name: user.nombre,
                    surname: user.apellido,
                    pic: user.foto,
                    id: userRequest.sis_id,
                }
                localStorage.setItem("aden_user", JSON.stringify(object));
                this.isLoading = false;
                this.router.navigate(['home']);
            },
            error => {
                this.isLoading = false;
            }
        );
    

    }

}
